import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';

(function () {

  if (!document.body.classList.contains('home')) return this;

  const sliders = document.querySelectorAll('.slider');

  if(sliders.length <= 0) return;

  sliders.forEach(function (item, index) {
    const slider = tns({
      container: '#thumb-slider'+(++index),
      loop: true,
      nav: false,
      controls: false,
      slideby: 'page',
      autoWidth: true,
      speed: '1000',
      // autoHeight: true,
      mouseDrag: true,
      swipeAngle: false,
    });
  });


})();
