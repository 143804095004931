// //for IE, Edge polyfill
import smoothscroll from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';
objectFitImages();
smoothscroll.polyfill();

import './_global-nav.js';
import './_single-page-bar.js';
import './_scroll-management.js'

import './_home.js';
