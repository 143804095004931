const header = document.querySelector('.site-header');
const globalNav = document.querySelector('.global-nav');
let globalNavItems = document.querySelectorAll('.global-nav__lists li');
    globalNavItems = Array.prototype.slice.call(globalNavItems, 0);
const globalNavMenu = document.querySelector('.global-nav__menu-btn');
const globalNavClose = document.querySelector('.global-nav__close-btn');

globalNavMenu.addEventListener('click', function (e) {
  if (document.body.classList.contains('menu-open')) {
    document.body.classList.remove('menu-open');
  } else {
    document.body.classList.add('menu-open');
  }
  e.stopImmediatePropagation();
});
globalNavClose.addEventListener('click', function (e) {
  if (document.body.classList.contains('menu-open')) {
    document.body.classList.remove('menu-open');
  }
  e.stopImmediatePropagation();
});

header.addEventListener('click', function (e) {
  if(!document.body.classList.contains('menu-open')) return;
  document.body.classList.remove('menu-open');
});

globalNavItems.forEach(function (item, index) {
  if(item.firstElementChild === null) return;
  item.firstElementChild.addEventListener('focus', function (e) {
    this.parentElement.parentElement.classList.add('focus');
  });
  item.firstElementChild.addEventListener('blur', function (e) {
    this.parentElement.parentElement.classList.remove('focus');
  });
});
