//single-page__bar
(function () {

  const menu = document.querySelector('.single-page__bar-label');

  if (!menu) return this;

  const pageBar = document.querySelector('.single-page__bar-label');

  const open_label = 'Text';
  const open_icon = 'right-arrow';
  const close_label = 'Photo';
  const close_icon = 'left-arrow';

  pageBar.addEventListener('click', function (e) {
    if (document.body.classList.contains('text-open')) {
      document.body.classList.remove('text-open');
      this.querySelector('.single-page__bar-sort').classList.replace( close_icon, open_icon);
      this.querySelector('.single-page__bar-sort').innerText = open_label;
    } else {
      document.body.classList.add('text-open');
      this.querySelector('.single-page__bar-sort').classList.replace(open_icon, close_icon);
      this.querySelector('.single-page__bar-sort').innerText = close_label;
      // scrollTo(0, 0);
    }
  });

})();
